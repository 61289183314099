@font-face {
  font-family: 'linoor-icon';
  src: url('../fonts/linoor-icon.eot?vvmfoz');
  src: url('../fonts/linoor-icon.eot?vvmfoz#iefix') format('embedded-opentype'),
    url('../fonts/linoor-icon.ttf?vvmfoz') format('truetype'),
    url('../fonts/linoor-icon.woff?vvmfoz') format('woff'),
    url('../fonts/linoor-icon.svg?vvmfoz#linoor-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="linoor-icon-"],
[class*=" linoor-icon-"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'linoor-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linoor-icon-dots-menu:before {
  content: "\e900";
}