@font-face {
  font-family: 'Flaticon';
  src:  url('../fonts/Flaticon.eot?h1k3hs');
  src:  url('../fonts/Flaticon.eot?h1k3hs#iefix') format('embedded-opentype'),
    url('../fonts/Flaticon.ttf?h1k3hs') format('truetype'),
    url('../fonts/Flaticon.woff?h1k3hs') format('woff'),
    url('../fonts/Flaticon.svg?h1k3hs#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="flaticon-"], [class*=" flaticon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Flaticon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-research:before {
  content: "\e91b";
}
.flaticon-satisfaction:before {
  content: "\e900";
}
.flaticon-idea:before {
  content: "\e901";
}
.flaticon-development:before {
  content: "\e902";
}
.flaticon-briefcase:before {
  content: "\e903";
}
.flaticon-planning:before {
  content: "\e904";
}
.flaticon-buildings:before {
  content: "\e905";
}
.flaticon-phone:before {
  content: "\e906";
}
.flaticon-headhunting:before {
  content: "\e907";
}
.flaticon-analysis:before {
  content: "\e908";
}
.flaticon-global:before {
  content: "\e909";
}
.flaticon-business:before {
  content: "\e90a";
}
.flaticon-background-check:before {
  content: "\e90b";
}
.flaticon-laptop:before {
  content: "\e90c";
}
.flaticon-visualization:before {
  content: "\e90d";
}
.flaticon-target:before {
  content: "\e90e";
}
.flaticon-presentation:before {
  content: "\e90f";
}
.flaticon-architect:before {
  content: "\e910";
}
.flaticon-data:before {
  content: "\e911";
}
.flaticon-creative:before {
  content: "\e912";
}
.flaticon-mobile-analytics:before {
  content: "\e913";
}
.flaticon-architect-1:before {
  content: "\e914";
}
.flaticon-mouse:before {
  content: "\e915";
}
.flaticon-moon:before {
  content: "\e916";
}
.flaticon-diamond:before {
  content: "\e917";
}
.flaticon-star:before {
  content: "\e918";
}
.flaticon-monitoring:before {
  content: "\e919";
}
.flaticon-development1:before {
  content: "\e91a";
}
.flaticon-business1:before {
  content: "\f100";
}
.flaticon-smartphone-call:before {
  content: "\f101";
}
.flaticon-three:before {
  content: "\f102";
}
.flaticon-symbol:before {
  content: "\f103";
}
.flaticon-line:before {
  content: "\f104";
}
.flaticon-sign:before {
  content: "\f105";
}
.flaticon-delete:before {
  content: "\f106";
}
.flaticon-check-symbol:before {
  content: "\f107";
}
.flaticon-round:before {
  content: "\f108";
}
.flaticon-delete-cross:before {
  content: "\f109";
}
.flaticon-location:before {
  content: "\f10a";
}
.flaticon-clipboard-with-a-list:before {
  content: "\f10b";
}
.flaticon-shelter:before {
  content: "\f10c";
}
.flaticon-technology:before {
  content: "\f10d";
}
.flaticon-fax-with-phone:before {
  content: "\f10e";
}
.flaticon-photo:before {
  content: "\f10f";
}
.flaticon-people:before {
  content: "\f110";
}
.flaticon-support:before {
  content: "\f111";
}
.flaticon-shape:before {
  content: "\f112";
}
.flaticon-cogwheel:before {
  content: "\f113";
}
.flaticon-arrows:before {
  content: "\f114";
}
.flaticon-home:before {
  content: "\f115";
}
.flaticon-cross:before {
  content: "\f116";
}
.flaticon-line-1:before {
  content: "\f117";
}
.flaticon-right-arrow:before {
  content: "\f118";
}
.flaticon-arrows-1:before {
  content: "\f119";
}
.flaticon-time:before {
  content: "\f11a";
}
.flaticon-letter:before {
  content: "\f11b";
}
.flaticon-search:before {
  content: "\f11c";
}
.flaticon-correct:before {
  content: "\f11d";
}
.flaticon-stats:before {
  content: "\f11e";
}
.flaticon-arrows-2:before {
  content: "\f11f";
}
.flaticon-arrows-3:before {
  content: "\f120";
}
.flaticon-arrows-4:before {
  content: "\f121";
}
.flaticon-arrows-5:before {
  content: "\f122";
}
.flaticon-checked:before {
  content: "\f123";
}
.flaticon-line-2:before {
  content: "\f124";
}
.flaticon-truck:before {
  content: "\f125";
}
.flaticon-headphones:before {
  content: "\f126";
}
.flaticon-garbage:before {
  content: "\f127";
}
.flaticon-heart:before {
  content: "\f128";
}
.flaticon-settings:before {
  content: "\f129";
}
.flaticon-link:before {
  content: "\f12a";
}
.flaticon-printer:before {
  content: "\f12b";
}
.flaticon-phone-call:before {
  content: "\f12c";
}
.flaticon-shopping-bag:before {
  content: "\f12d";
}
.flaticon-envelope:before {
  content: "\f12e";
}
.flaticon-bars:before {
  content: "\f12f";
}
.flaticon-remove:before {
  content: "\f130";
}
.flaticon-check:before {
  content: "\f131";
}
.flaticon-link-1:before {
  content: "\f132";
}
.flaticon-check-1:before {
  content: "\f133";
}
.flaticon-add:before {
  content: "\f134";
}
.flaticon-menu:before {
  content: "\f135";
}
.flaticon-anchor:before {
  content: "\f136";
}
.flaticon-menu-1:before {
  content: "\f137";
}
.flaticon-arrows-6:before {
  content: "\f138";
}
.flaticon-success:before {
  content: "\f139";
}
.flaticon-add-1:before {
  content: "\f13a";
}
.flaticon-interface:before {
  content: "\f13b";
}
.flaticon-up-arrow:before {
  content: "\f13c";
}
.flaticon-arrows-7:before {
  content: "\f13d";
}
.flaticon-arrows-8:before {
  content: "\f13e";
}
.flaticon-arrows-9:before {
  content: "\f13f";
}
.flaticon-user:before {
  content: "\f140";
}
.flaticon-bar-chart:before {
  content: "\f141";
}
.flaticon-speech-bubble:before {
  content: "\f142";
}
.flaticon-placeholder:before {
  content: "\f143";
}
.flaticon-technology-1:before {
  content: "\f144";
}
.flaticon-technology-2:before {
  content: "\f145";
}
.flaticon-link-2:before {
  content: "\f146";
}
.flaticon-trophy:before {
  content: "\f147";
}
.flaticon-layers:before {
  content: "\f148";
}
.flaticon-home-1:before {
  content: "\f149";
}
.flaticon-message:before {
  content: "\f14a";
}
.flaticon-maps-and-flags:before {
  content: "\f14b";
}
.flaticon-delete-1:before {
  content: "\f14c";
}
.flaticon-cancel:before {
  content: "\f14d";
}
.flaticon-menu-2:before {
  content: "\f14e";
}
.flaticon-briefcase1:before {
  content: "\f14f";
}
.flaticon-agenda:before {
  content: "\f150";
}
.flaticon-arrows-10:before {
  content: "\f151";
}
.flaticon-menu-3:before {
  content: "\f152";
}
.flaticon-photo-1:before {
  content: "\f153";
}
.flaticon-clipboard:before {
  content: "\f154";
}
.flaticon-heart-1:before {
  content: "\f155";
}
.flaticon-logout:before {
  content: "\f156";
}
.flaticon-location-pin:before {
  content: "\f157";
}
.flaticon-quality:before {
  content: "\f158";
}
.flaticon-quote:before {
  content: "\f159";
}
.flaticon-right-quote:before {
  content: "\f15a";
}
.flaticon-hand-shake:before {
  content: "\f15b";
}
.flaticon-chat:before {
  content: "\f15c";
}
.flaticon-next:before {
  content: "\f15d";
}
.flaticon-back:before {
  content: "\f15e";
}
.flaticon-next-1:before {
  content: "\f15f";
}
.flaticon-arrow:before {
  content: "\f160";
}
.flaticon-telephone:before {
  content: "\f161";
}
.flaticon-smartphone:before {
  content: "\f162";
}
.flaticon-placeholder-1:before {
  content: "\f163";
}
.flaticon-placeholder-2:before {
  content: "\f164";
}
.flaticon-menu-4:before {
  content: "\f165";
}
.flaticon-menu-5:before {
  content: "\f166";
}
.flaticon-menu-6:before {
  content: "\f167";
}
.flaticon-letter-x:before {
  content: "\f168";
}
.flaticon-magnifying-glass:before {
  content: "\f169";
}
.flaticon-link-3:before {
  content: "\f16a";
}
.flaticon-chat-comment-oval-speech-bubble-with-text-lines:before {
  content: "\f16b";
}
.flaticon-expand:before {
  content: "\f16c";
}
.flaticon-share:before {
  content: "\f16d";
}
.flaticon-worldwide:before {
  content: "\f16e";
}
.flaticon-magnifying-glass-1:before {
  content: "\f16f";
}
.flaticon-left-arrow:before {
  content: "\f170";
}
.flaticon-left:before {
  content: "\f171";
}
.flaticon-left-arrow-1:before {
  content: "\f172";
}
.flaticon-right:before {
  content: "\f173";
}
.flaticon-right-1:before {
  content: "\f174";
}
.flaticon-right-arrow-1:before {
  content: "\f175";
}
.flaticon-left-quote:before {
  content: "\f176";
}
.flaticon-left-quote-1:before {
  content: "\f177";
}
.flaticon-play-button:before {
  content: "\f178";
}
.flaticon-play-button-1:before {
  content: "\f179";
}
.flaticon-link-4:before {
  content: "\f17a";
}
.flaticon-zoom-in:before {
  content: "\f17b";
}
.flaticon-zoom-out:before {
  content: "\f17c";
}
.flaticon-postcard:before {
  content: "\f17d";
}
.flaticon-right-2:before {
  content: "\f17e";
}
.flaticon-left-1:before {
  content: "\f17f";
}
.flaticon-magnifying-glass-2:before {
  content: "\f180";
}
.flaticon-shopping-cart:before {
  content: "\f181";
}
.flaticon-left-arrow-2:before {
  content: "\f182";
}
.flaticon-send:before {
  content: "\f183";
}
.flaticon-case:before {
  content: "\f184";
}
.flaticon-calendar:before {
  content: "\f185";
}
.flaticon-place:before {
  content: "\f186";
}
.flaticon-settings-1:before {
  content: "\f187";
}
.flaticon-worldwide-1:before {
  content: "\f188";
}
.flaticon-139-tick:before {
  content: "\f189";
}
.flaticon-tick-1:before {
  content: "\f18a";
}
.flaticon-shopping-bag-1:before {
  content: "\f18b";
}
.flaticon-confirmation:before {
  content: "\f18c";
}
.flaticon-search-1:before {
  content: "\f18d";
}
.flaticon-lock:before {
  content: "\f18e";
}
.flaticon-iphone:before {
  content: "\f18f";
}
.flaticon-mobile-phone:before {
  content: "\f190";
}
.flaticon-down-arrow:before {
  content: "\f191";
}
.flaticon-down-arrow-1:before {
  content: "\f192";
}
.flaticon-down-chevron:before {
  content: "\f193";
}
.flaticon-play-button-2:before {
  content: "\f194";
}
.flaticon-play-1:before {
  content: "\f195";
}
.flaticon-menu-7:before {
  content: "\f196";
}
.flaticon-smartphone-1:before {
  content: "\f197";
}
.flaticon-email:before {
  content: "\f198";
}
.flaticon-email-1:before {
  content: "\f199";
}
.flaticon-fast:before {
  content: "\f19a";
}
.flaticon-search-2:before {
  content: "\f19b";
}
.flaticon-shopping-bag-2:before {
  content: "\f19c";
}
.flaticon-smartphone-2:before {
  content: "\f19d";
}
.flaticon-smartphone-3:before {
  content: "\f19e";
}
.flaticon-placeholder-3:before {
  content: "\f19f";
}
.flaticon-envelope-1:before {
  content: "\f1a0";
}
.flaticon-responsive:before {
  content: "\f1a1";
}
.flaticon-loupe:before {
  content: "\f1a2";
}
.flaticon-loupe-1:before {
  content: "\f1a3";
}
.flaticon-graphic-designer:before {
  content: "\f1a4";
}
.flaticon-computer:before {
  content: "\f1a5";
}
.flaticon-digital-marketing:before {
  content: "\f1a6";
}
.flaticon-marketing-online:before {
  content: "\f1a7";
}
.flaticon-development2:before {
  content: "\f1a8";
}
.flaticon-app-development:before {
  content: "\f1a9";
}
.flaticon-ui:before {
  content: "\f1aa";
}
.flaticon-ux:before {
  content: "\f1ab";
}
.flaticon-pin:before {
  content: "\f1ac";
}
.flaticon-pin-1:before {
  content: "\f1ad";
}
.flaticon-email-2:before {
  content: "\f1ae";
}
.flaticon-phone-call-1:before {
  content: "\f1af";
}
.flaticon-call:before {
  content: "\f1b0";
}
.flaticon-call-1:before {
  content: "\f1b1";
}
.flaticon-vector:before {
  content: "\f1b2";
}
.flaticon-3d:before {
  content: "\f1b3";
}
.flaticon-creativity:before {
  content: "\f1b4";
}
.flaticon-digital:before {
  content: "\f1b5";
}
.flaticon-viral-marketing:before {
  content: "\f1b6";
}
.flaticon-instant-camera:before {
  content: "\f1b7";
}
.flaticon-monitor:before {
  content: "\f1b8";
}
.flaticon-design-tools:before {
  content: "\f1b9";
}
.flaticon-idea1:before {
  content: "\f1ba";
}
.flaticon-fast-1:before {
  content: "\f1bb";
}
.flaticon-clock:before {
  content: "\f1bc";
}
.flaticon-quick-response:before {
  content: "\f1bd";
}
.flaticon-fast-2:before {
  content: "\f1be";
}
.flaticon-user-1:before {
  content: "\f1bf";
}
.flaticon-user-2:before {
  content: "\f1c0";
}
.flaticon-speech-bubble-1:before {
  content: "\f1c1";
}
.flaticon-speech-bubble-2:before {
  content: "\f1c2";
}
.flaticon-chat-1:before {
  content: "\f1c3";
}
.flaticon-comments:before {
  content: "\f1c4";
}
.flaticon-comment:before {
  content: "\f1c5";
}
.flaticon-comment-1:before {
  content: "\f1c6";
}
.flaticon-clock-1:before {
  content: "\f1c7";
}
.flaticon-clock-2:before {
  content: "\f1c8";
}
.flaticon-clock-3:before {
  content: "\f1c9";
}
.flaticon-menu-8:before {
  content: "\f1ca";
}
.flaticon-menu-9:before {
  content: "\f1cb";
}
.flaticon-menu-10:before {
  content: "\f1cc";
}
.flaticon-list:before {
  content: "\f1cd";
}
.flaticon-menu-11:before {
  content: "\f1ce";
}
.flaticon-list-1:before {
  content: "\f1cf";
}
.flaticon-email-3:before {
  content: "\f1d0";
}
.flaticon-letter-1:before {
  content: "\f1d1";
}
.flaticon-email-4:before {
  content: "\f1d2";
}
.flaticon-contact-book:before {
  content: "\f1d3";
}
.flaticon-collaboration:before {
  content: "\f1d4";
}
.flaticon-network:before {
  content: "\f1d5";
}
.flaticon-enterprise:before {
  content: "\f1d6";
}
.flaticon-group:before {
  content: "\f1d7";
}
.flaticon-team:before {
  content: "\f1d8";
}
.flaticon-work-team:before {
  content: "\f1d9";
}
.flaticon-globe:before {
  content: "\f1da";
}
.flaticon-credit-card:before {
  content: "\f1db";
}
.flaticon-online-banking:before {
  content: "\f1dc";
}
.flaticon-desktop:before {
  content: "\f1dd";
}
.flaticon-desktop-1:before {
  content: "\f1de";
}
.flaticon-remove-1:before {
  content: "\f1df";
}
.flaticon-minus:before {
  content: "\f1e0";
}
.flaticon-add-2:before {
  content: "\f1e1";
}
.flaticon-plus:before {
  content: "\f1e2";
}
.flaticon-minus-1:before {
  content: "\f1e3";
}
.flaticon-magic-wand:before {
  content: "\f1e4";
}
.flaticon-vector-1:before {
  content: "\f1e5";
}
.flaticon-sketch:before {
  content: "\f1e6";
}
.flaticon-vector-2:before {
  content: "\f1e7";
}
.flaticon-vector-3:before {
  content: "\f1e8";
}
.flaticon-computer-graphic:before {
  content: "\f1e9";
}
.flaticon-idea-1:before {
  content: "\f1ea";
}
.flaticon-vector-4:before {
  content: "\f1eb";
}
.flaticon-hosting:before {
  content: "\f1ec";
}
.flaticon-quality-1:before {
  content: "\f1ed";
}
.flaticon-web-design:before {
  content: "\f1ee";
}
.flaticon-cms:before {
  content: "\f1ef";
}
.flaticon-3d-design:before {
  content: "\f1f0";
}
.flaticon-3d-1:before {
  content: "\f1f1";
}
