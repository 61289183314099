
.page-title {
    text-align: center;
    margin-bottom: 2rem;
    padding: 20px 0;
    background-color: transparent; 
    color: #001f3f; 
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .page-title h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }

  .captive-page h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #162d3e; 
  }
  
  .captive-page p {
    font-size: 1rem;
    line-height: 1.8;
    color: #3d5170; 
  }
  
  .captive-page img {
    max-width: 100%;
    display: block;
    border-radius: 8px; 
  }
  
  .captive-page a {
    color: #10f404; 
    text-decoration: none;
  }
  
  .captive-page a:hover {
    text-decoration: underline;
  }
  
  .captive-page {
    display: flex;
    flex-direction: row;
  }
  
  .sidebar {
   position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #162d3e;
  padding: 25px;
  color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  transform: translateX(0);
  }
  
  
  .sidebar-logo img {
    width: 200px;
    margin-bottom: 2rem;
  }
  
  .sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-nav li {
    margin: 1.5rem 0;
  }
  
  .sidebar-nav a {
    color: #fff;
    font-size: 1.1rem;
    display: block;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .sidebar-nav a:hover {
    text-decoration: underline;
    color: #10f404;
    transform: scale(1.05);
  }
  
  .main-content {
    margin-left: 300px; /* Adjusted to match sidebar width */
    padding: 40px 20px;
    background-color: #f5f8fa; /* Light neutral background */
  }
  
  /* Welcome Section */
  .welcome-section {
    display: flex;
    align-items: center;
    padding: 50px;
    background-color: #f5f8fa; /* Neutral light background */
    border-radius: 10px;
    margin-bottom: 3rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow */
      transition: transform 0.3s ease, box-shadow 0.3s ease;

  }
  .welcome-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }
  
  .welcome-section .profile {
    flex: 1;
    text-align: center;
  }
  .winner-section .profile {
    flex: 1;
    text-align: center;
  }
  
  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #162d3e; /* Dark blue border */
    margin-bottom: 1rem;
  }
  
  .welcome-text {
    flex: 2;
    padding-left: 30px;
  }
  
  .welcome-text p {
    font-size: 1.2rem;
    font-weight: 300;
    color: #162d3e; /* Tendanz dark blue */
    line-height: 1.6;
    font-style: italic;
  }
  .welcome-section .profile {
    flex: 1;
    text-align: center; /* Centers content horizontally */
    display: flex; /* Use flex for vertical centering */
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
  }
  
  .winner-section .profile {
    flex: 1;
    text-align: center; /* Centers content horizontally */
    display: flex; /* Use flex for vertical centering */
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
  }
  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #162d3e; /* Dark blue border */
    margin-bottom: 1rem; /* Space below the image */
  }
  
  .section-with-image-text {
    display: flex;
    align-items: stretch;
    margin-bottom: 3rem;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  .section-with-image-text:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }
  .section-with-image-text.reverse {
    flex-direction: row-reverse;
  }
  
  .section-with-image-text .image {
    flex: 1;
    max-width: 45%;
  }
  
  .section-with-image-text .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .section-with-image-text .content {
    flex: 1;
    padding: 0 40px;
  }
  
  .section-with-image-text:not(.reverse) .content {
    padding-left: 60px; /* Extra margin when the image is on the left */
  }
  
  .section-with-image-text.reverse .content {
    padding-left: 20px; /* Reset margin when the image is on the right */
    padding-right: 20px;
  }
  .section-with-image-text h2 {
    font-size: 1.8rem;
    color: #162d3e;
    margin-bottom: 1rem;
  }
  
  .section-with-image-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #3d5170;
    margin-bottom: 1.5rem;
  }
  
  .section-with-image-text ul {
    list-style: none; 
    padding-left: 0; 
    margin: 0;
  }
  
  .section-with-image-text ul li {
    font-size: 1rem;
    line-height: 1.6;
    color: #3d5170;
    margin-bottom: 0.5rem;
    position: relative; 
    padding-left: 20px; 
  }
  
  .section-with-image-text ul li::before {
    content: "✓"; 
    font-size: 1rem; 
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); 
  }
  
  .winner-section {
    display: flex;
    align-items: center;
    padding: 50px;
    background-color: #f5f8fa; 
    border-radius: 10px;
    margin-bottom: 3rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
      transition: transform 0.3s ease, box-shadow 0.3s ease;

  }
  .winner-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }

  .sidebar-footer{
    margin-top: auto; 
  }
  
  @media (max-width: 768px) {
    .section-with-image-text {
      flex-direction: column;
      text-align: center;
    }
  
    .section-with-image-text.reverse {
      flex-direction: column;
    }
  
    .section-with-image-text .image,
    .section-with-image-text .content {
      max-width: 100%;
    }
  
    .section-with-image-text .content {
      padding: 20px 0;
    }
  }
  
  
  /* Testimonials Section */
.testimonials-section {
    background-color: #f5f8fa; /* Neutral light background */
    border-radius: 10px;
    padding: 50px 20px;
    margin-bottom: 3rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .testimonials-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }
  
  .testimonials-section h2 {
    text-align: center;
    color: #162d3e; /* Tendanz dark blue */
    margin-bottom: 2rem;
  }
  
  .testimonials-container {
    display: flex;
    flex-wrap: nowrap; /* Ensure items stay side by side */
    gap: 30px;
    justify-content: space-between; /* Distribute space evenly */
  }
  
  .testimonial-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex: 1; /* Each card takes equal width */
    text-align: center;
  }
  
  .testimonial-card img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 1rem;
    border: 4px solid #162d3e; /* Dark blue border */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .testimonial-card h3 {
    font-size: 1.5rem; /* Bigger name text */
    color: #162d3e; /* Tendanz dark blue */
    margin-bottom: 0.5rem;
  }
  
  .testimonial-card .job {
    font-size: 1rem;
    color: #3d5170;
    margin-bottom: 1rem;
  }
  
  .testimonial-card .description {
    font-size: 1rem;
    line-height: 1.6;
    color: #3d5170;
    text-align: center;
    font-style: italic;
  }


  
  @media (max-width: 768px) {
    .testimonials-container {
      flex-wrap: wrap; /* Stack items on smaller screens */
    }
  
    .testimonial-card {
      width: 100%; /* Each card takes full width on small screens */
      margin-bottom: 1rem;
    }
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 200px;
    }
  
    .main-content {
      margin-left: 220px;
    }
  
    .welcome-section,
    .section-with-image-text {
      flex-direction: column;
      text-align: center;
    }
  
    .section-with-image-text .content {
      padding-left: 0;
    }
  
    .testimonials-container {
      flex-direction: column;
    }
  
    .testimonial-card {
      width: 90%;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 480px) {
    .sidebar {
      width: 180px;
    }
  
    .main-content {
      margin-left: 200px;
    }
  
    .profile-img {
      width: 120px;
      height: 120px;
    }

    
  }

  @media (max-width: 768px) {
    .sidebar {
      width: 250px;
      transform: translateX(-100%);
      display: none; /* Ensure sidebar is removed from layout on mobile */
    }
  
    .sidebar.active {
      transform: translateX(0); /* Show sidebar when active */
      display: flex; /* Ensure it's visible when active */
    }
  
    .hamburger-menu {
      display: block; /* Show hamburger on mobile */
      font-size: 30px;
      background: transparent;
      border: #162d3e;
      color: #162d3e;
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 101; /* Ensure it is on top of the sidebar */
    }
    
  
    .main-content {
      margin-left: 0; /* Remove margin when sidebar is hidden */
    }
  }
  
  /* Hide hamburger on larger screens */
  @media (min-width: 769px) {
    .hamburger-menu {
      display: none; /* Hide hamburger button on larger screens */
    }
  
    .sidebar {
      transform: translateX(0); /* Sidebar is always visible on larger screens */
      display: flex; /* Ensure it's visible */
    }
  }
  